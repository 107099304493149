body {
  background: url("../public/img/vertical.jpeg"), url("../public/img/vertical2_flip.jpeg");
  background-repeat: no-repeat, repeat;
  background-color: black;

  background-position-x:  center;
  font-family: MYRIADPRO-REGULAR;             
  color: rgb(255, 255, 255);
  margin: 0;
  height: 100%;
  padding: 0;
}


@font-face {
  font-family: android-101;
  src: url("../public/font/Android\ 101.ttf");
}
@font-face {
  font-family: MYRIADPRO-BOLD;
  src: url("../public/font/MYRIADPRO-BOLD.OTF");
}
@font-face {
  font-family: MYRIADPRO-BOLDCOND;
  src: url("../public/font/MYRIADPRO-BOLDCOND.OTF");
}

@font-face {
  font-family: MYRIADPRO-COND;
  src: url("../public/font/MYRIADPRO-COND.OTF");
}

@font-face {
  font-family: MYRIADPRO-REGULAR;
  src: url("../public/font/MYRIADPRO-REGULAR.OTF");
}


.youtube-video{
  width: 560px;
  max-width: 100%;
  height: 315px;
  margin: 5px;
}

.banner {
  font-family: android-101;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  margin-right: 15px;
}

.signgoogle{
  height: auto;
  max-width: 85%;
}

a {
  color: white;
  text-decoration: none;
}

.banner img {
  width: 130px;
  height: auto;
  margin: 0 15px;
}

.contact-us {
  color: white;
  font-size: 20px;
  margin: 0 20px;
  text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.6);
}


.section1{
  /* background-image:  url("../public/img/wallpaper2.jpeg"); */
  /* background: linear-gradient(to bottom, purple, black); */
  background-size: cover;
  padding-bottom: 100px;
}

.section2{
  /* background-image: url("../public/img/wallpaper4.jpeg"); */
  background-size: cover;
  padding-bottom: 100px;

}


.main-title {
  font-family: android-101;
  text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.6);
  color: #ffffff;
  font-size: 55px;
  padding-top: 40px;
  margin-right: 10%;
  margin-left: 10%;
}

.sub-title {
  font-family: android-101;
  color: white;
  padding-top: 150px;
  padding-bottom: 20px;
  font-size: 45px;
  margin: 0;
  text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.6);
}

.sub-title2 {
  font-family: android-101;
  color: white;
  padding-top: 50px;
  font-size: 40px;
  margin: 0;
  text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.6);
}

.sub-title3 {
  font-family: android-101;
  color: white;
  padding-top: 50px;
  padding-bottom: 20px;
  font-size: 35px;
  margin: 0;
  text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.6);
}


.sub-text{
  padding-top: 0px;
  margin-left: 10%;
  margin-right: 10%;
  font-family: MYRIADPRO-REGULAR;
  font-size: 25px;
}

.partner {
  width: 250px;
  padding-top: 50px;
  padding-bottom: 50px;
}



.sub-title-description {
  color: white;
  font-size: 20px;
  padding-bottom: 30px;
  margin: 0;
  text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.6);
}


.badge{
  padding-inline: 40px;
  height: 150px;
}

.conf_logo{
  padding-inline: 40px;
  padding-top: 30px;
  height: 80px;
}

.voice{
  padding-inline: 40px;
  padding-top: 30px;
  height: 150px;
}

.social_logo{
  padding-inline: 15px;
  padding-top: 30px;
  padding-bottom: 20px;
  height: 30px;
  
}


.manage-account {
  font-family: MYRIADPRO-REGULAR;
  background-color: #ffffff;
  border-radius: 5px;
  color: rgb(56, 56, 56);
  padding: 20px;
  font-size: 15px;
  width: fit-content;
  min-width: 50%;
  margin: 20px auto;
}

.signin{
  color: rgb(35, 35, 35);
  font-family: MYRIADPRO-REGULAR;
}

.about-section {
  font-family: MYRIADPRO-REGULAR;
  background-color: #0000004b;
  border-radius: 5px;
  padding: 20px;
  max-width: 800px;
  font-size: 25px;
  margin: 20px auto;
}

.list_files{
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  
}

.list_files a{
    color: black;
    text-decoration: none;
    font-size: 15px;
}

.file_individual{
  font-size: 10px;
  margin: 10px;
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}





.about-section li {
  font-family: MYRIADPRO-REGULAR;
  text-align: left;

  padding: 8px;
}

.file_icon{
  height: 50px;
}



.about-section p {
  font-size: 17px;
  color: #ffffff;
  line-height: 1.5;
  text-align: justify;
}



.footer-basic {
  font-family: android-101;
  padding-top: 100px;
  padding-bottom: 20px;
  color:#4b4c4d;
  width: 100%;
}


.footer-basic .copyright {
  margin-top:0px;
  text-align:center;
  font-size:12px;
  color:#ffffff;
  margin-bottom:0;
}
